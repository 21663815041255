<template>
  <div></div>
</template>

<script>

export default {
  name: "Home",
  mounted() {
    this.$router.push("/objects");
  }
};
</script>
